import { Loading3QuartersOutlined } from "@ant-design/icons";

const CenterSpinner = () => {
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
      <Loading3QuartersOutlined spin style={{ fontSize: 30 }} />
    </div>
  );
};

export default CenterSpinner;
