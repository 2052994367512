import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CenterSpinner from "./CenterSpinner";
const Dashboard = lazy(() => import("../../modules/dashboard"));
const User = lazy(() => import("../../modules/user"));
const Client = lazy(() => import("../../modules/client"));
const Food = lazy(() => import("../../modules/food"));
const FoodCombo = lazy(() => import("../../modules/foodcombo"));
const ComboItems = lazy(() => import("../../modules/foodcombo/comboitems"));
const Recipe = lazy(() => import("../../modules/Recipe"));
const RecipeItems = lazy(() => import("../../modules/Recipe/items"));
const FoodTag = lazy(() => import("../../modules/food/tag/List"));
const Video = lazy(() => import("../../modules/videolibrary"));
const Payments = lazy(() => import("../../modules/payments"));
const FoodRequest = lazy(() => import("../../modules/food_request"));
const DailyStatus = lazy(() => import("../../modules/dailystatus"));
const CallLog = lazy(() => import("../../modules/call_log"));

const Loader = ({ children }) => (
  <Suspense fallback={<CenterSpinner />}>{children}</Suspense>
);

const ConsoleRoutes = () => {
  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <Loader>
            <Dashboard />
          </Loader>
        }
      />

      <Route
        path="user/*"
        element={
          <Loader>
            <User />
          </Loader>
        }
      />

      <Route
        path="client/*"
        element={
          <Loader>
            <Client />
          </Loader>
        }
      />

      <Route
        path="food/*"
        element={
          <Loader>
            <Food />
          </Loader>
        }
      />
      <Route
        path="foodcombo/"
        element={
          <Loader>
            <FoodCombo />
          </Loader>
        }
      />
      <Route
        path="foodcombo/:id"
        element={
          <Loader>
            <ComboItems />
          </Loader>
        }
      />
      <Route
        path="recipe/"
        element={
          <Loader>
            <Recipe />
          </Loader>
        }
      />
      <Route
        path="recipe/:id"
        element={
          <Loader>
            <RecipeItems />
          </Loader>
        }
      />

      <Route
        path="foodtag/*"
        element={
          <Loader>
            <FoodTag />
          </Loader>
        }
      />
      <Route
        path="video/*"
        element={
          <Loader>
            <Video />
          </Loader>
        }
      />
      <Route
        path="payments/*"
        element={
          <Loader>
            <Payments />
          </Loader>
        }
      />
      <Route
        path="foodrequest/*"
        element={
          <Loader>
            <FoodRequest />
          </Loader>
        }
      />
      <Route
        path="dailystatus/*"
        element={
          <Loader>
            <DailyStatus />
          </Loader>
        }
      />
      <Route
        path="call-log/*"
        element={
          <Loader>
            <CallLog />
          </Loader>
        }
      />

      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
};

export default ConsoleRoutes;
