import cookie from "js-cookie";

// set cookie
export const setCookie = (key, value) => {
  if (!!window) {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

//remove from cookie
export const removeCookie = (key) => {
  if (!!window) {
    cookie.remove(key);
  }
};

//get from cookie
export const getCookie = (key) => {
  if (!!window) {
    return cookie.get(key);
  }
};

// set localStorage
export const setLocalStorage = (key, value) => {
  if (!!window) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove from localStorage
export const removeLocalStorage = (key) => {
  if (!!window) {
    localStorage.removeItem(key);
  }
};

//authenticate user
export const authenticate = (data, next) => {
  setCookie("accesstoken", data.token);
  setLocalStorage("user", data.user);
  next();
};

export const isAuth = () => {
  if (
    !!window &&
    !!getCookie("accesstoken") &&
    !!localStorage.getItem("user")
  ) {
    return JSON.parse(localStorage.getItem("user"));
  }
  return false;
};

export const signout = (next) => {
  removeCookie("accesstoken");
  removeLocalStorage("user");
  next();
};

export const getToken = () => {
  const token = getCookie("accesstoken");
  if (!!window && token) {
    return token;
  }
  return "";
};
