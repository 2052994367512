import React from "react";
import { Layout, Menu } from "antd";
import {
  PieChartOutlined,
  UserOutlined,
  StarOutlined,
  // PlaySquareOutlined,
  FileDoneOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;
const keys = [
  "dashboard",
  "client",
  "food",
  "foodcombo",
  "foodtag",
  "recipe",
  "video",
  "onlinepayment",
  "receipts",
  "foodrequest",
  "dailystatus",
  "call-log",
];

const ConsoleSider = () => {
  const location = useLocation();
  let selectedKey;
  keys.forEach((i) => {
    const uri = location.pathname.split("/");
    if (uri.indexOf(i) > 0) selectedKey = i;
  });

  return (
    <Sider
      theme="light"
      width={200}
      className="body-height overflow-y-auto appscrollbar disable-select"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={[selectedKey]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item
          key="dashboard"
          icon={<PieChartOutlined style={{ fontSize: 16 }} />}
        >
          <Link to="/console/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          key="client"
          icon={<UserOutlined style={{ fontSize: 16 }} />}
        >
          <Link to="/console/client/list">Clients</Link>
        </Menu.Item>
        <Menu.Item
          key="call-log"
          icon={<FileTextOutlined style={{ fontSize: 16 }} />}
        >
          <Link to="/console/call-log">Call Log</Link>
        </Menu.Item>

        <SubMenu
          key="foodmenu"
          icon={<StarOutlined style={{ fontSize: 16 }} />}
          title="Food calories"
        >
          <Menu.Item key="food">
            <Link to="/console/food/list">Food</Link>
          </Menu.Item>
          <Menu.Item key="foodtag">
            <Link to="/console/foodtag">Food Tags</Link>
          </Menu.Item>
          <Menu.Item key="foodcombo">
            <Link to="/console/foodcombo">Food Combo</Link>
          </Menu.Item>
          <Menu.Item key="recipe">
            <Link to="/console/recipe">Recipe</Link>
          </Menu.Item>
          <Menu.Item key="foodrequest">
            <Link to="/console/foodrequest">Request food</Link>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item
          key="video"
          icon={<PlaySquareOutlined style={{ fontSize: 16 }} />}
        >
          <Link to="/console/video">Video Library</Link>
        </Menu.Item> */}
        <Menu.Item
          key="dailystatus"
          icon={<FileDoneOutlined style={{ fontSize: 16 }} />}
        >
          <Link to="/console/dailystatus">Daily Status</Link>
        </Menu.Item>

        <SubMenu
          key="payments"
          icon={<StarOutlined style={{ fontSize: 16 }} />}
          title="Payments"
        >
          <Menu.Item key="onlinepayment">
            <Link to="/console/payments/onlinepayment">Online Payment</Link>
          </Menu.Item>
          <Menu.Item key="receipts">
            <Link to="/console/payments/receipts">Receipts</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default ConsoleSider;
