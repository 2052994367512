import React from "react";
import { useUser } from "../../store/userState";
import AdminMenu from "./Admin.Menu";
import ManagerMenu from "./Manager.Menu";

const ConsoleSider = () => {
  const { user } = useUser();

  if (user?.role === 0) return <AdminMenu />;
  else if (user?.role === 1) return <ManagerMenu />;
  else return null;
};

export default ConsoleSider;
