import "./style/Print.less";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import Logo from "./assets/image/logo.png";
const fetcher = ({ queryKey }) => {
  const { id } = queryKey[1];
  const url = `${process.env.REACT_APP_API_BASE}/admin/foodcombo/item/download/${id}`;
  return axios.get(url).then(({ data }) => data);
};

const Print = () => {
  const { id } = useParams();
  const { isLoading, data } = useQuery(["print-combo", { id }], fetcher);
  // const navigate = useNavigate();

  useEffect(() => {
    if (!data) return;
    if (document.readyState === "complete") window.print();
  }, [data, isLoading]);

  if (isLoading) return <p>Loading ...</p>;

  return (
    <div className="combo-print-wrapper">
      <img src={Logo} alt="TDS Pro" />
      <h3 style={{ padding: "10px" }}>SAMPLE DIET PLAN</h3>
      <div className={"overall-macro"}>
        <h2
          style={{
            textAlign: "center",
          }}
        >{`${
          data.combo_meta.calorie_category || data.macro.calories
        } Calories`}</h2>
        <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
          {data.combo_meta.type}
        </h3>
        <table className="overall">
          <tr>
            <td>Protein</td>
            <td className="text-right">{data.macro.protein} g</td>
          </tr>
          <tr>
            <td>Carb</td>
            <td className="text-right">{data.macro.carb} g</td>
          </tr>
          <tr>
            <td>Fat</td>
            <td className="text-right">{data.macro.fat} g</td>
          </tr>
          <tr>
            <td>Fiber</td>
            <td className="text-right">{data.macro.fiber} g</td>
          </tr>
          <tr>
            <td>Total Calories</td>
            <td className="text-right">{data.macro.calories}</td>
          </tr>
        </table>
      </div>

      {data?.breakfast && (
        <div className="dine-wrapper">
          <h2 className="dine-title">Breakfast</h2>
          <table className="dine">
            <tr>
              <th className="text-left">Item</th>
              <th className="text-right" style={{ width: "100px" }}>
                Qty.
              </th>
              <th className="text-right" style={{ width: "100px" }}>
                Calories
              </th>
            </tr>
            {data?.breakfast.items.map((item) => (
              <tr>
                <td>
                  {item.item}
                  <br />
                  {item.item_ta}
                </td>
                <td className="text-right">{`${item.combo_qty} ${item.unit}`}</td>
                <td className="text-right">{item.calories}</td>
              </tr>
            ))}
          </table>
          <div className="macro">
            <p>Protein {data?.breakfast.macro.protein}</p>
            <p>Carb {data?.breakfast.macro.carb}</p>
            <p>Fat {data?.breakfast.macro.fat}</p>
            <p>Fiber {data?.breakfast.macro.fiber}</p>
            <p>Calories {data?.breakfast.macro.calories}</p>
          </div>
        </div>
      )}

      {data?.lunch && (
        <div className="dine-wrapper">
          <h2 className="dine-title">Lunch</h2>
          <table className="dine">
            <tr>
              <th className="text-left">Item</th>
              <th className="text-right" style={{ width: "100px" }}>
                Qty.
              </th>
              <th className="text-right" style={{ width: "100px" }}>
                Calories
              </th>
            </tr>
            {data?.lunch.items.map((item) => (
              <tr>
                <td>
                  {item.item}
                  <br />
                  {item.item_ta}
                </td>
                <td className="text-right">{`${item.combo_qty} ${item.unit}`}</td>
                <td className="text-right">{item.calories}</td>
              </tr>
            ))}
          </table>
          <div className="macro">
            <p>Protein {data?.lunch.macro.protein}</p>
            <p>Carb {data?.lunch.macro.carb}</p>
            <p>Fat {data?.lunch.macro.fat}</p>
            <p>Fiber {data?.lunch.macro.fiber}</p>
            <p>Calories {data?.lunch.macro.calories}</p>
          </div>
        </div>
      )}

      {data?.dinner && (
        <div className="dine-wrapper">
          <h2 className="dine-title">Dinner</h2>
          <table className="dine">
            <tr>
              <th className="text-left">Item</th>
              <th className="text-right" style={{ width: "100px" }}>
                Qty.
              </th>
              <th className="text-right" style={{ width: "100px" }}>
                Calories
              </th>
            </tr>
            {data?.dinner.items.map((item) => (
              <tr>
                <td>
                  {item.item}
                  <br />
                  {item.item_ta}
                </td>
                <td className="text-right">{`${item.combo_qty} ${item.unit}`}</td>
                <td className="text-right">{item.calories}</td>
              </tr>
            ))}
          </table>
          <div className="macro">
            <p>Protein {data?.dinner.macro.protein}</p>
            <p>Carb {data?.dinner.macro.carb}</p>
            <p>Fat {data?.dinner.macro.fat}</p>
            <p>Fiber {data?.dinner.macro.fiber}</p>
            <p>Calories {data?.dinner.macro.calories}</p>
          </div>
        </div>
      )}

      {data?.snacks && (
        <div className="dine-wrapper">
          <h2 className="dine-title">Snacks</h2>
          <table className="dine">
            <tr>
              <th className="text-left">Item</th>
              <th className="text-right" style={{ width: "100px" }}>
                Qty.
              </th>
              <th className="text-right" style={{ width: "100px" }}>
                Calories
              </th>
            </tr>
            {data?.snacks.items.map((item) => (
              <tr>
                <td>
                  {item.item}
                  <br />
                  {item.item_ta}
                </td>
                <td className="text-right">{`${item.combo_qty} ${item.unit}`}</td>
                <td className="text-right">{item.calories}</td>
              </tr>
            ))}
          </table>
          <div className="macro">
            <p>Protein {data?.snacks.macro.protein}</p>
            <p>Carb {data?.snacks.macro.carb}</p>
            <p>Fiber {data?.snacks.macro.fiber}</p>
            <p>Calories {data?.snacks.macro.calories}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Print;
