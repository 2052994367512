import React from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  SolutionOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
const { SubMenu } = Menu;

const { Sider } = Layout;
const keys = [
  "dashboard",
  "student",
  "course",
  "batch",
  "collection",
  "modelclass",
  "enrollment",
  "student-fee",
  "batch-fee",
];

const ConsoleSider = () => {
  const location = useLocation();
  let selectedKey;
  keys.forEach((i) => {
    const uri = location.pathname.split("/");
    if (uri.indexOf(i) > 0) selectedKey = i;
  });

  return (
    <Sider
      theme="light"
      width={200}
      className="body-height overflow-y-auto appscrollbar disable-select">
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={[selectedKey]}
        style={{ height: "100%", borderRight: 0 }}>
        <Menu.Item
          key="student"
          icon={<UserOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/student/list">Students</Link>
        </Menu.Item>
        <Menu.Item
          key="enrollment"
          icon={<SolutionOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/enrollment">Enrollments</Link>
        </Menu.Item>

        <SubMenu key="fee" icon={<DollarOutlined />} title="Fee">
          <Menu.Item key="student-fee">
            <Link to="/console/student-fee">Student Fee</Link>
          </Menu.Item>
          {/* <Menu.Item key="batch-fee">
            <Link to="/console/batch-fee">Batch Fee</Link>
          </Menu.Item> */}
        </SubMenu>

        <Menu.Item
          key="modelclass"
          icon={<FileTextOutlined style={{ fontSize: 16 }} />}>
          <Link to="/console/modelclass">Model Class</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default ConsoleSider;
